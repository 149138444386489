<template>
	<div class=" container">
		<span v-b-toggle="'collapse' + id">
			<span class="when-open"
				><div class="svgWrapper">
					<svg
						version="1.1"
						id="itc-sat"
						xmlns="http://www.w3.org/2000/svg"
						x="0"
						y="0"
						width="180"
						height="218"
						transform=" scale(0.2 0.2) rotate(90 -350 -250)"
					>
						<path
							class="st0"
							d="M112.4 1.7c6.8 6.5 12.9 13.7 18.3 21.5 11.9 17.2 19.6 36.2 23.4 56.7 1.2 6.5 1.9 13 2.3 19.6.4 7.6.3 15.1-.3 22.6-2.2 24.7-9.8 47.5-23.3 68.3-5.5 8.4-11.9 16.2-19 23.3-.5.5-1.1 1-1.6 1.5-.2-.4.1-.6.3-.8 14-19.9 23.8-41.7 29.2-65.4 2-8.6 3.3-17.2 4-26 .5-6.2.8-12.4.6-18.7-.4-16.8-3-33.2-7.9-49.3-5.9-18.7-14.4-36-25.6-52.1-.2-.3-.4-.7-.7-1-.1-.2.1-.3.3-.2z"
						/>
						<path
							class="st0"
							d="M108.6 46.1c6.1 5.7 11.3 12.1 15.7 19.1 5.5 8.7 9.6 18 11.7 28.2 2.9 13.9 1.8 27.5-3.1 40.8-4.3 11.8-10.9 22.1-19.2 31.4-1.5 1.6-3 3.2-4.6 4.7-.2.1-.3 0-.3-.3 4.1-6.2 7.3-12.8 9.8-19.8 2.3-6.7 3.9-13.7 4.9-20.7 1.1-8 1.5-16.1 1.4-24.1-.3-15.3-2.5-30.3-8.5-44.5-2.2-5.1-4.9-10-8-14.6 0-.3.1-.3.2-.2zM69.1 104.3c0-8.1 6.4-14.5 14.5-14.5s14.5 6.5 14.5 14.6c0 8-6.6 14.5-14.6 14.5-7.9-.1-14.4-6.6-14.4-14.6z"
						/>
					</svg>
				</div> </span
			><span class="when-closed">
				<div class="svgWrapper">
					<svg
						version="1.1"
						id="itc-sat"
						xmlns="http://www.w3.org/2000/svg"
						x="0"
						y="0"
						width="180"
						height="218"
						transform=" scale(0.2 0.2) rotate(180 -270 50)"
					>
						<path
							class="st0"
							d="M112.4 1.7c6.8 6.5 12.9 13.7 18.3 21.5 11.9 17.2 19.6 36.2 23.4 56.7 1.2 6.5 1.9 13 2.3 19.6.4 7.6.3 15.1-.3 22.6-2.2 24.7-9.8 47.5-23.3 68.3-5.5 8.4-11.9 16.2-19 23.3-.5.5-1.1 1-1.6 1.5-.2-.4.1-.6.3-.8 14-19.9 23.8-41.7 29.2-65.4 2-8.6 3.3-17.2 4-26 .5-6.2.8-12.4.6-18.7-.4-16.8-3-33.2-7.9-49.3-5.9-18.7-14.4-36-25.6-52.1-.2-.3-.4-.7-.7-1-.1-.2.1-.3.3-.2z"
						/>
						<path
							class="st0"
							d="M108.6 46.1c6.1 5.7 11.3 12.1 15.7 19.1 5.5 8.7 9.6 18 11.7 28.2 2.9 13.9 1.8 27.5-3.1 40.8-4.3 11.8-10.9 22.1-19.2 31.4-1.5 1.6-3 3.2-4.6 4.7-.2.1-.3 0-.3-.3 4.1-6.2 7.3-12.8 9.8-19.8 2.3-6.7 3.9-13.7 4.9-20.7 1.1-8 1.5-16.1 1.4-24.1-.3-15.3-2.5-30.3-8.5-44.5-2.2-5.1-4.9-10-8-14.6 0-.3.1-.3.2-.2zM69.1 104.3c0-8.1 6.4-14.5 14.5-14.5s14.5 6.5 14.5 14.6c0 8-6.6 14.5-14.6 14.5-7.9-.1-14.4-6.6-14.4-14.6z"
						/>
					</svg>
				</div>
			</span>
			<h3>
				<slot name="version"></slot>
			</h3>
		</span>
		<b-collapse :id="'collapse' + id" v-model="open">
			<div class="ml-5">
				<slot name="notes"></slot>
			</div>
		</b-collapse>
	</div>
</template>

<script>
export default {
	props: ['id', 'open'],
};
</script>

<style scoped>
.container {
	background: white;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
	display: none;
}
.svgWrapper {
	display: inline-block;
	width: 19px;
	height: 23px;
	transform-box: fill-box;
	transform-origin: center;
	/* animation: spinner 2s ease-in-out infinite; */
}
.st0 {
	fill: #fec938;
}
</style>
